@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #f9fdff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  html {
    scroll-behavior: auto;
  }
}
.react-multiple-carousel__arrow--right {
  right: 5px !important;
  background: rgba(255, 255, 255, 0.5) !important;
}
.react-multiple-carousel__arrow--left {
  left: 5px !important;
  background: rgba(255, 255, 255, 0.5) !important;
}
.react-multiple-carousel__arrow {
  z-index: 4 !important;
  color: #2191fb !important;
}
/* .react-multiple-carousel__arrow::before {
  color: #2191fb !important;
}
.Snackbar_snackbar-wrapper-top-center__1Gbfi {
  top: 100px !important;
} */
